<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent
              title="Templates de capacités d'une activité"
            />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row
                no-gutters
                justify="space-between"
                v-if="!loading && activity"
              >
                <div>
                  Détail des templates de capacités pour l'activité
                  {{ activity.digitalName }}
                </div>
              </v-row>
            </v-card-title>

            <!-- Treeview -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="12" align-self="center">
                  <TreeViewTemplateCapacityComponent
                    :treeNodeFactory="treeNodeFactory"
                    :tree="treemodel"
                    :editable="false"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { ActivityService } from "@/service/sfr/activity_service.js";
import { CapacitiesService } from "@/service/etablishment/capacities_service.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import TreeViewTemplateCapacityComponent from "@/components/ui/TreeViewCapacity/TreeViewTemplateCapacityComponent.vue";
import {
  TreeNodeFactory,
  TreeModelConverter,
} from "@/components/ui/TreeViewCapacity/TemplateTreeService.js";
import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "AddCapacities",
  components: {
    Workflow,
    TitleAndReturnComponent,
    TreeViewTemplateCapacityComponent,
  },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      treeNodeFactory: TreeNodeFactory,
      treeModelConverter: TreeModelConverter,
      /**en cours de chargement. */
      loading: false,

      /**les service d'accès au APIs. */
      capacitiesService: null,
      activitiesService: null,

      /** l'activité éditée */
      activity: null,

      capacities: null,
      treemodel: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;
        this.activity = await this.activitiesService.getActivityById(
          this.activityId
        );
        let capacitiesList =
          await this.capacitiesService.getCapacityTemplatesByActivity(
            this.activityId
          );
        this.capacities = {
          capacities: capacitiesList,
        };
        this.treemodel = this.treeModelConverter.convertDtoToTreeModel(
          this.capacities
        );
      } catch (error) {
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    canSave() {
      return true;
    },

    /**retourne true si entité est modifié */
    entityHasChanged() {
      let changed = false;
      return changed;
    },
  },
  mounted() {
    // initialisation des services vers l'api
    this.activitiesService = new ActivityService(this.$api);
    this.capacitiesService = new CapacitiesService(
      this.$api.getCapacitiesApi()
    );

    // initialisation des classes utilitaires de manipulation de l'arbre
    this.treeNodeFactory = new TreeNodeFactory();
    this.treeModelConverter = new TreeModelConverter(this.treeNodeFactory);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    if (this.$route.query) {
      if (this.$route.query.activity) {
        this.activityId = this.$route.query.activity;
      }
    }
    this.load();
  },
};
</script>
